import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import auth from "@/auth";
import NotFound from "@/views/NotFound.vue";
import VueHead from "vue-head";
import LoginRoot from "@/views/login/LoginRoot.vue";
import EmailLogin from "@/views/login/EmailLogin.vue";
import SuperAdminLogin from "@/views/login/SuperAdminLogin.vue";
import ActivateAccount from "@/views/login/ActivateAccount.vue";
import ResetCode from "@/views/login/ResetCode.vue";
import companyDirectoryRoutes from "@/router/company_directory.router";
import activityFeedRoutes from "@/router/activity_feed.router";
import adminRoutes from "@/router/admin.router";
import scoreboardRouter from "@/router/scoreboard.router";
import departmentRouter from "@/router/department.router";
import companyRoutes from "@/router/company_router";
import documentRoutes from "@/router/document_router";
import formRouter from "@/router/form_router";
import inspectionRouter from "@/router/guardian_inspection.router";
import surveyRouter from "@/router/survey.router";
import companyDocumentRoutes from "@/router/company_document.router";
import employeeDocumentRoutes from "@/router/employee_document.router";
import expenseReportRoutes from "@/router/expense_report.router";
import ActivityLayout from "@/views/activity/ActivityLayout.vue";
import AdminLayout from "@/views/admin/AdminLayout.vue";
import ExpenseReportLayout from "@/views/expense_report/ReportLayout.vue";
import ProfileBrief from "@/views/dashboard/ProfileBrief.vue";
import CompanyRoot from "@/views/super_admin/CompanyRoot.vue";
import CompanyDocumentRoot from "@/views/company_documents/CompanyDocumentsLayout.vue";
import InactiveUser from "@/views/super_admin/inactive_user/InactiveEmployees.vue";
import TarfLayout from "@/views/forms/TarfLayout.vue";
import DocumentRoot from "@/views/ed_approval/DocumentRoot.vue";
import EmployeeDocumentRoot from "@/views/employee_document/EmployeeDocumentsLayout.vue";
import InspectionLayout from "@/views/guardian_angel/InspectionLayout.vue";
import SurveyLayout from "@/views/survey/SurveyLayout.vue";
import CalendarLayout from "@/views/calendar/CalendarLayout.vue";
Vue.use(VueHead, { separator: "|", complement: "CareCens" });
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "app-layout" */ "../layouts/full_layout/Layout.vue"
      ),
    children: [
      {
        path: "/company-directory",
        component: () =>
          import(
            /* webpackChunkName: "company-directory" */ "../views/company_directory/CompanyDirectoryRoot.vue"
          ),
        meta: {
          title: "Company Directory",
          requiresAuth: true,
        },
        children: companyDirectoryRoutes,
      },
      {
        path: "/expense-report",
        component: ExpenseReportLayout,
        meta: {
          title: "Expense Report",
          requiresAuth: true,
        },
        children: expenseReportRoutes,
      },
      {
        path: "/departments",
        component: () =>
          import(
            /* webpackChunkName: "departments" */ "../views/departments/DepartmentRoot.vue"
          ),
        meta: {
          title: "Departments",
          requiresAuth: true,
        },
        children: departmentRouter,
      },
      {
        path: "/scoreboard",
        component: () =>
          import(
            /* webpackChunkName: "scoreboard" */ "@/views/scoreboard/ScoreboardLayout.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        children: scoreboardRouter,
      },
      {
        path: "/activity",
        component: ActivityLayout,
        meta: {
          requiresAuth: true,
        },
        children: activityFeedRoutes,
      },
      {
        path: "/inactive-user",
        component: InactiveUser,
        meta: {
          title: "Inactivated User",
          requiresAuth: true,
        },
      },
      {
        path: "/admin",
        component: AdminLayout,
        meta: {
          requiresAuth: true,
        },
        children: adminRoutes,
      },
      {
        path: "/form",
        component: TarfLayout,
        meta: {
          requiresAuth: true,
        },
        children: formRouter,
      },
      {
        path: "/",
        component: ProfileBrief,
        meta: {
          title: "Dashboard",
          requiresAuth: true,
        },
      },
      {
        path: "/company-list",
        component: CompanyRoot,
        meta: {
          title: "Company Root",
          requiresAuth: true,
        },
        children: companyRoutes,
      },
      {
        path: "/document-approval",
        component: DocumentRoot,
        meta: {
          title: "Employee Document",
          requiresAuth: true,
        },
        children: documentRoutes,
      },
      {
        path: "/company-document",
        component: CompanyDocumentRoot,
        meta: {
          title: "Company Document",
          requiresAuth: true,
        },
        children: companyDocumentRoutes,
      },
      {
        path: "/employee-document",
        component: EmployeeDocumentRoot,
        meta: {
          title: "Employee Document",
          requiresAuth: true,
        },
        children: employeeDocumentRoutes,
      },
      {
        path: "/guardian-angel",
        component: InspectionLayout,
        meta: {
          title: "Company Root",
          requiresAuth: true,
        },
        children: inspectionRouter,
      },
      {
        path: "/survey",
        component: SurveyLayout,
        meta: {
          title: "Survey",
          requiresAuth: true,
        },
        children: surveyRouter,
      },
      {
        path: "/calendar",
        component: CalendarLayout,
        meta: {
          title: "Calendar",
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: LoginRoot,
    meta: {
      guest: true,
    },
    children: [
      {
        path: "",
        components: {
          default: EmailLogin,
        },
        meta: {
          guest: true,
          title: "Login",
        },
      },
      {
        path: "/reset-code",
        components: {
          default: ResetCode,
        },
        meta: {
          guest: true,
          title: "Reset Code",
        },
      },
      {
        path: "/activate-account",
        components: {
          default: ActivateAccount,
        },
        meta: {
          guest: true,
          title: "Activate Account",
        },
      },
      {
        path: "/reset-password",
        components: {
          default: ActivateAccount,
        },
        meta: {
          guest: true,
          title: "Reset Password",
        },
      },
      {
        path: "/superadmin/login",
        components: {
          default: SuperAdminLogin,
        },
        meta: {
          guets: true,
          title: "Super Admin Login",
        },
      },
      {
        path: "/forgot-companycode",
        components: {
          default: ResetCode,
        },
        meta: {
          guets: true,
          title: "Forgot Company Code",
        },
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = await auth.isAuthenticated();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      if (localStorage.getItem("isSuperAdmin")) {
        localStorage.removeItem("isSuperAdmin");
        next({
          path: "/superadmin/login",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      }
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!loggedIn) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = previousNearestWithMeta?.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]"),
  ).map(el => el?.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: never) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: never) => document.head.appendChild(tag));

  next();
});

export default router;
