

















































































































import { Component, Vue } from "vue-property-decorator";
import EventForm from "@/views/calendar/EventForm.vue";
import { CalendarEvent } from "@/api";
import { CalEvent } from "@/common/CalendarEvent";
import moment from "moment";
import { addDayOnDate } from "@/common/date_format";

type CustomDate = {
  date: string;
};

@Component({
  components: {
    EventForm,
  },
})
export default class Calendarlayout extends Vue {
  counter = 0;
  deleting = false;
  isUpdate = false;
  eventDialog = false;
  eventTypeDialog = false;
  type = "month";
  types = ["month", "week", "day"];
  typeIcon = "mdi-view-grid";
  typeIcons = ["mdi-view-grid", "mdi-view-column", "mdi-view-agenda"];
  typeIndex = 0;
  value = "";
  selectedOpen = false;
  selectedElement: EventTarget | null = null;
  selectedEvent: CalEvent = {
    id: 0,
    name: "",
    description: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    color: "",
    timed: false,
  };

  get events() {
    const events: CalEvent[] = [];
    const eventList = this.$store.getters["calendar/events"] || [];

    eventList.forEach((item: CalendarEvent) => {
      const format = item.timed ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm";
      events.push({
        id: item.id,
        name: item.name,
        description: item.description,
        start: moment(item.startDate)
          .local()
          .format(format),
        end: moment(item.endDate)
          .local()
          .format(format),
        startTime: item.timed ? "" : moment(item.startDate).format("HH:mm"),
        endTime: item.timed ? "" : moment(item.endDate).format("HH:mm"),
        color: item.color,
        timed: item.timed,
      });
    });

    return events;
  }

  getEvents({ start, end }: { start: CustomDate; end: CustomDate }) {
    if (this.type != "month") return;
    this.$store.dispatch("calendar/fetchEvents", {
      beginAt: start.date,
      endAt: addDayOnDate(end.date),
    });
  }

  viewDay({ date }: { date: string }) {
    this.value = date;
    this.typeIndex = 1;
    this.changeIcon(false);
  }

  changeIcon(isDay: boolean) {
    this.typeIndex++;
    if (this.typeIndex >= this.types.length) this.typeIndex = 0;
    if (isDay) this.value = moment().format("YYYY-MM-DD");
    this.type = this.types[this.typeIndex];
    this.typeIcon = this.typeIcons[this.typeIndex];
  }

  showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent | TouchEvent;
    event: CalEvent;
  }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true)),
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  getEventDateFormatted(date: string, timed: boolean) {
    const format = timed ? "MM/DD/YYYY" : "MM/DD/YYYY @ HH:mm:ss";
    return moment(date)
      .local()
      .format(format);
  }

  clearSelectedEvent() {
    this.selectedEvent = {
      id: 0,
      name: "",
      description: "",
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      color: "",
      timed: false,
    };
  }

  async deleteEvent() {
    this.deleting = true;
    await this.$store.dispatch("calendar/deleteEvent", this.selectedEvent);
    this.deleting = false;
    this.selectedOpen = false;
  }
}
